import HeroTest from "../../src/assets/img/heroBanna.jpg";
import HeroBanner from "../../src/assets/img/Home Essentials.jpg";
import HeroBannerTwo from "../../src/assets/img/Enjoy Discount.jpg";
import HeroBannerThree from "../../src/assets/img/Happy Shoppers.jpg";
import HeroBannerFour from "../../src/assets/img/Shop Global Save Big.jpg";

import Val from "../../src/assets/img/TOFA Val landing page design.jpg";
import Hairr from "../../src/assets/img/Hair & Wig Sales on TOFA Marketplace.jpg";
import NewYear from "../../src/assets/img/New Year Bliss  Discount Sales WEB.jpg";
import T1212 from "../../src/assets/img/12.12  Discount Sales web.jpg";

import Hair from "../../src/assets/img/Hair & wig.jpg";
import Beauty from "../../src/assets/img/Beauty S.jpg";
import Clothing from "../../src/assets/img/Clothing.jpg";
import Watch from "../../src/assets/img/Wrist Watches.jpg";
import Electronics from "../../src/assets/img/Electronics.jpg";
import PhoneAcc from "../../src/assets/img/Phone A.jpg";
import HomeAppliances from "../../src/assets/img/Home A.jpg";
import OfficeAppliances from "../../src/assets/img/Office S.jpg";
import KitchenAppliance from "../../src/assets/img/Kitchen A.jpg";

export const homeImageSlider = [
  {
    src: Val,
    description: "Happy valentine",
  },
  {
    src: T1212,
    description: "12 12",
  },
  {
    src: NewYear,
    description: "new year",
  },
  {
    src: Hairr,
    description: "Hair",
  },
  {
    src: HeroTest,
    description: "This is the first slide",
  },
  {
    src: HeroBanner,
    description: "This is the first slide",
  },
  {
    src: HeroBannerTwo,
    description: "This is the second slide",
  },
  {
    src: HeroBannerThree,
    description: "This is the second slide",
  },
  {
    src: HeroBannerFour,
    description: "This is the second slide",
  },
];

export const aliexpressCategories = [
  {
    category: "Hair & Wig ",
    link: "NG Hair 7.23",
    imgUrl: Hair,
  },
  {
    category: "Fashions",
    link: "AEB_Clothing_NG",
    imgUrl: Clothing,
  },
  {
    category: "Wrist Watches",
    link: "NG watches 719",
    imgUrl: Watch,
  },
  {
    category: "Home Appliances",
    link: "NG home appliances",
    imgUrl: HomeAppliances,
  },
  {
    category: "Electronics",
    link: "NG electronic 7.19",
    imgUrl: Electronics,
  },
  {
    category: "Phone & Office Supplies",
    link: "AEB_Phone&OfficeSupplies_NG",
    imgUrl: OfficeAppliances,
  },
  {
    category: "Beauty Supplies",
    link: "AEB_BeautySupplies_NG",
    imgUrl: Beauty,
  },
  {
    category: "Phones &  Accessories",
    link: "phones&accessories_ZA topsellers_ 20240423",
    imgUrl: PhoneAcc,
  },
  {
    category: "Home & Kitchen",
    link: "AEB_Home&Kitchen_NG",
    imgUrl: KitchenAppliance,
  },
];

export const languages = [
  {
    code: "EUR",
    name: "EUR",
    country_code: "EUR",
  },
  {
    code: "GBP",
    name: "GBP",
    country_code: "GBP",
  },
  {
    code: "GHS",
    name: "GHS",
    country_code: "GHS",
  },
  {
    code: "NGN",
    name: "NGN",
    country_code: "NGN",
  },
  {
    code: "USD",
    name: "USD",
    country_code: "USD",
  },
  {
    code: "XOF",
    name: "XOF",
    country_code: "XOF",
  },
];
