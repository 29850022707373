import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useFlutterwave } from "flutterwave-react-v3";
import toast from "react-hot-toast";
import cookies from "js-cookie";

import { axiosInstance } from "../baseUrl";
import { useEventTag } from "./useEventTag";
import { GlobalContext } from "../utils/GlobalState";
import { AliexpressCartContext } from "../utils/AliexpressCartState";
import {
  addTwoPercent,
  numberWithCommas,
  removeFirstPlus,
  separateCountryCode,
  calculateTwoPercent,
  sumTwoNumbers,
  toastOptions,
} from "../helpers/functionHelpers";
import { useDeleteBulkProductCart } from "./useDeleteBulkProductCart";
import { useUpdateOrderStatus } from "./useUpdateOrderStatus";
import { useSendOrderInvoiceAsEmail } from "./useSendOrderInvoiceAsEmail";
import { useMoMoPayment } from "./useMoMoPayment";
import { CurrencyExchangeContext } from "../utils/CurrencyExchangeState";
import { useCreateTransactPay } from "./useCreateTransactPay";
import { combineOrderIds } from "../helpers/combineIds";
import ShortUniqueId from "short-unique-id";
import { useUpdateOrderPayment } from "./useUpdateOrderPayment";
import { getErrorMessages } from "../helpers/errorHelpers";
import { getLocationDetails } from "../helpers/locationHelpers";
import { createInvoice } from "../helpers/invoiceHelpers";
import { handleAliExpressError } from "../helpers/aliexpressErrorHelpers";

export const useCreateOrder = () => {
  const title = process.env.REACT_APP_COMPANY_TITLE;
  const selectedCurrency = cookies.get("currency") || "NGN";
  const { convertCurrency } = useContext(CurrencyExchangeContext);

  const [searchParams] = useSearchParams();
  const logisticsStatus = searchParams.get("status") || "";

  const { user, cartItems, totalCartQuantity } = useContext(GlobalContext);
  const { cart, totalShippingFee } = useContext(AliexpressCartContext);
  const { handleSendInvoice } = useSendOrderInvoiceAsEmail();
  const {
    loadMoMo,
    handleMoMoPayment,
    handleCancelMoMoPaymentModal,
    openMoMoPaymentModal,
    setOpenMoMoPaymentModal,
    formatCountdown,
    handleConfirmMoMoPayment,
    isCodeStep,
    countdown,
    confirmLoadMoMo,
  } = useMoMoPayment();
  // const { handleCreateTransactPay } = useCreateTransactPay();
  const { handleOrderPaymentUpdate } = useUpdateOrderPayment();

  const { handleEventTag } = useEventTag();
  const { handleDelete } = useDeleteBulkProductCart();
  const { updateOrderStatus } = useUpdateOrderStatus();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedCurrenci, setSelectedCurrenci] = useState("");
  const [paymentTerm, setPaymentTerm] = useState("");
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [momoPaymentDetails, setMomoPaymentDetails] = useState({
    phoneNumber: "",
    referralCode: "",
    note: "",
  });

  const [userDetails, setUserDetails] = useState({
    port: "",
    incoterm: "",
    shippingType: "",
  });

  const currentSelectedCurrency = selectedCurrenci || selectedCurrency;

  const handleMoMoPaymentDetailsChange = (e) => {
    const { name, value } = e.target;
    setMomoPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const errors = getErrorMessages(user, userDetails, paymentTerm);

  // Total cost for just aliexpress products
  const totalCost = cart?.reduce((sum, item) => {
    const cost = parseFloat(item.cost);
    const quantity = item.quantity;
    const total = sum + cost * quantity;
    return total;
  }, 0);

  //aliexpress shipping fee
  const aliexpressShippingFee = convertCurrency(
    totalShippingFee,
    "USD",
    currentSelectedCurrency
  );

  const aliexpressTotalItemCostAndHandlingFee = convertCurrency(
    addTwoPercent(totalCost),
    cart[0]?.currency,
    currentSelectedCurrency
  );

  const aliexpressTotalCostafterConvertToSelectedCurrency = sumTwoNumbers(
    aliexpressTotalItemCostAndHandlingFee,
    aliexpressShippingFee
  );

  // total cost for just tofa products without the commas
  function removeCommasAndConvert() {
    if (!cartItems?.cartPrice) {
      return 0;
    }
    const tofaTotalCost = convertCurrency(
      cartItems?.cartPrice,
      "USD",
      currentSelectedCurrency
    );
    let noCommas = tofaTotalCost?.replace(/,/g, "");
    return Number(noCommas);
  }

  // total display cost when cart has both aliexpress products and tofa products or just aliexpress product or just tofas products.

  const grandTotal = () => {
    if (totalCartQuantity > 0 && cart.length > 0) {
      return sumTwoNumbers(
        removeCommasAndConvert(),
        aliexpressTotalCostafterConvertToSelectedCurrency
      );
    } else if (totalCartQuantity > 0 && cart.length < 1) {
      return removeCommasAndConvert();
    } else if (totalCartQuantity < 1 && cart.length > 0) {
      return aliexpressTotalCostafterConvertToSelectedCurrency;
    } else return "0.00";
  };

  // transact pay
  const [sdkLoaded, setSdkLoaded] = useState(false); // To track if the SDK is loaded

  useEffect(() => {
    if (!sdkLoaded) {
      // Check if script is already present
      if (
        !document.querySelector(
          'script[src="https://payment-web-sdk.transactpay.ai/v1/checkout"]'
        )
      ) {
        const script = document.createElement("script");
        script.src = "https://payment-web-sdk.transactpay.ai/v1/checkout";
        script.async = true;

        script.onload = () => {
          setSdkLoaded(true);
        };

        script.onerror = () => {
          console.error("Failed to load Payment SDK");
          alert("Failed to load payment SDK. Please try again later.");
        };

        document.body.appendChild(script);
      } else {
        setSdkLoaded(true); // SDK is already present
      }
    }
  }, [sdkLoaded]);

  const InitiateTransactPay = (transactionId, invoiceDetails, allOrderIds) => {
    if (
      !sdkLoaded ||
      !window.CheckoutNS ||
      !window.CheckoutNS.PaymentCheckout
    ) {
      alert("Payment SDK not loaded. Please try again.");
      return;
    }

    const Checkout = new window.CheckoutNS.PaymentCheckout({
      firstName: user?.firstName,
      lastName: user?.LastName,
      mobile: user?.phoneNumber,
      country: "NGN",
      email: user?.email,
      currency: "NGN",
      amount: grandTotal(),
      reference: transactionId,
      redirectUrl: "https://marketplase.tradersofafrica.com",
      merchantReference: transactionId,
      description: "Payment for services",
      apiKey: process.env.REACT_APP_TRANSACTPAY_PUBLIC_KEY,
      encryptionKey: process.env.REACT_APP_TRANSACTPAY_ENCRYPTION_KEY,
      onCompleted: (data) => {
        console.log(data);
        if (data?.status?.toLowerCase() === "successful") {
          setPaymentSuccessful(true);
          toast.success(data.message ?? "Payment successful", toastOptions);
          handleSendInvoice(invoiceDetails);
          handleOrderPaymentUpdate(
            "PAYNOW",
            transactionId,
            "PAID",
            allOrderIds
          );
        } else if (data?.status?.toLowerCase() === "failed") {
          toast.error(
            data.message ?? "Payment failed, please try again",
            toastOptions
          );
        }
      },
      onClose: () => {
        navigate("/checkout");
        console.log("Payment cancelled, please refresh");
      },
      onError: (error) => {
        console.log(
          error.message ?? "Payment attempt failed, please try again"
        );
        navigate("/checkout");
      },
    });
    Checkout.init();
  };

  //the end of transact pay

  const flutterwaveKey = process.env.REACT_APP_FLUTTERWAVE_API_KEY;

  // config for flutterwave
  const config = {
    public_key: flutterwaveKey,
    tx_ref: Date.now(),
    amount: grandTotal(),
    currency: currentSelectedCurrency,
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user.email,
      phone_number: user.phoneNumber,
      name: user.firstName + " " + user.LastName,
    },
    customizations: {
      title: title,
      description: "Payment for items in cart",
      logo: "https://res.cloudinary.com/dcnehcocw/image/upload/v1714313366/Screenshot_2024-04-28_at_3.08.04_PM_dk2ifr.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  // handling momo payment
  const handleSubmitMoMoPayment = () => {
    const momoPayload = {
      notify_url: "https://marketplase.tradersofafrica.com/",
      channel: "nigeria_momo_collection",
      amount: grandTotal(),
      currency: currentSelectedCurrency,
      time_expire: "300",
      channel_extra: {
        payer: {
          partyId: removeFirstPlus(momoPaymentDetails.phoneNumber),
          partyIdType: "MSISDN",
        },
        payeeNote: momoPaymentDetails.note,
        payerMessage: "Thank you for buying from Traders of Africa marketplace",
      },
    };
    handleMoMoPayment(momoPayload);
  };

  // modifying aliexpress products array to match what's expected in the payload
  const modifiedCart = cart?.map((productDetails) => {
    const { quantity, productId, sku_attr } = productDetails;
    return {
      logistics_service_name: "CAINIAO_FULFILLMENT_STD",
      sku_attr: sku_attr,
      product_count: quantity,
      product_id: productId,
    };
  });

  // modifying tofa products array to match what's expected in the payload
  const modifiedCartItems =
    cartItems?.cart &&
    cartItems?.cart?.map((obj) => {
      const { productId, quantity, product, totalPrice } = obj;
      return {
        productID: productId,
        sellerId: product?.userId,
        cost: convertCurrency(totalPrice, "USD", currentSelectedCurrency),
        quantityOrdered: quantity?.toString(),
        productName: product?.productName,
        productDescription: product?.productDescription,
        countryOfOrigin: product?.countryOfOrigin || "Nigeria",
        logisticsStatus: logisticsStatus === "successful" ? "PAID" : "UNPAID",
        currencyType: currentSelectedCurrency,
      };
    });

  //invoice for aliexpress

  const aliexpressInvoice = createInvoice(
    cart,
    convertCurrency,
    currentSelectedCurrency
  );

  // invoice object for tofa
  const tofaInvoice = createInvoice(
    cartItems?.cart,
    convertCurrency,
    currentSelectedCurrency
  );

  const createInvoiceDetails = (aliexpressOrderId) => {
    return {
      userEmail: user?.email,
      userPhoneNumber: user?.phoneNumber,
      userName: `${user?.firstName} ${user?.LastName}`,
      userAddress: user?.email,
      grandTotal: `${currentSelectedCurrency} ${numberWithCommas(
        grandTotal()
      )}`,
      shippingFee: `${currentSelectedCurrency} ${numberWithCommas(
        aliexpressShippingFee
      )}`,
      handlingFee: `${currentSelectedCurrency} ${numberWithCommas(
        convertCurrency(
          calculateTwoPercent(totalCost),
          cart[0]?.currency,
          currentSelectedCurrency
        )
      )}`,
      tax: `${currentSelectedCurrency} 0.00`,
      orderDetails: {
        orderIds: aliexpressOrderId(),
        products: aliexpressInvoice,
        totalAmount: numberWithCommas(
          aliexpressTotalCostafterConvertToSelectedCurrency
        ),
      },
      orderDetails2: {
        products2: tofaInvoice,
        totalAmount2: numberWithCommas(removeCommasAndConvert()),
      },
    };
  };

  // creating order
  const handleSubmit = async (address) => {
    if (totalCartQuantity < 1 && cart.length < 1) {
      return;
    }

    if (!address) {
      toast.error(`Please enter an address.`, toastOptions);
      return;
    }

    await getLocationDetails(address);

    const error = errors.find((error) => error.condition);
    if (totalCartQuantity > 0 && error) {
      toast.error(error.message, toastOptions);
      return;
    }

    if (!paymentTerm || paymentTerm === "Select payment method") {
      toast.error("Please select payment method.", toastOptions);
      return;
    }

    let promises = [];

    setLoading(true);

    // payload for tofa's order
    if (totalCartQuantity > 0) {
      const tofaOrderPayload = {
        bulkOrder: modifiedCartItems,
        destination: user?.address,
        port:
          userDetails.incoterm === "LOCAL_DELIVERY" ? "None" : userDetails.port,
        note: "None",
        incoterm: userDetails.incoterm,
        shippingType: userDetails.shippingType,
        paymentTerm:
          paymentTerm === "MoMo"
            ? "PAYNOW"
            : paymentTerm === "TransactPay"
            ? "PAYNOW"
            : paymentTerm,
      };

      const tofaPromise = axiosInstance.post(
        "/order/bulk-order",
        tofaOrderPayload
      );

      promises.push(tofaPromise);
    }

    // payload for aliexpress order
    if (cart.length > 0) {
      const { countryCode, mainNumber } = separateCountryCode(
        user?.phoneNumber
      );
      const locationDetails = await getLocationDetails(address);

      const { streetNo, home, street, city, state, countyShort, zipCode } =
        locationDetails;
      const aliexpressOrderPayload = {
        dsExtendRequest: {
          payment: {
            pay_currency: "NGN",
          },
        },
        paramPlaceOrderRequest4OpenAPIDTO: {
          product_items: modifiedCart,
          logistics_address: {
            full_name: `${user?.firstName + " " + user?.LastName}`,
            contact_person: `${user?.firstName + " " + user?.LastName}`,
            address: `${streetNo + " " + home + " " + street}`,
            address2: user?.address,
            city: city,
            province: `${state + " state"}`,
            country: countyShort,
            zip: zipCode,
            phone_country: countryCode,
            mobile_no: mainNumber,
            locale: "en_US",
          },
        },
      };

      const aliexpressPromise = axiosInstance.post(
        "/ali-express/order/create",
        aliexpressOrderPayload
      );
      promises.push(aliexpressPromise);
    }

    try {
      const [data, data1] = await Promise.all(promises);
      if (
        totalCartQuantity > 0 &&
        cart.length > 0 &&
        handleAliExpressError(data1)
      ) {
        return;
      }

      if (
        totalCartQuantity < 1 &&
        cart.length > 0 &&
        handleAliExpressError(data)
      ) {
        return;
      }
      if (totalCartQuantity > 0 && data) {
        localStorage.setItem(
          "orderIds",
          JSON.stringify(data.data.data.orderIDs)
        );
      }

      // aliexpress order id
      const aliexpressOrderId = () => {
        if (
          totalCartQuantity > 0 &&
          cart.length > 0 &&
          data1?.data?.aliexpress_ds_order_create_response?.result?.order_list
            ?.number
        ) {
          return data1?.data?.aliexpress_ds_order_create_response?.result
            ?.order_list?.number;
        } else if (
          totalCartQuantity < 1 &&
          cart.length > 0 &&
          data?.data?.aliexpress_ds_order_create_response?.result?.order_list
            ?.number
        ) {
          return data?.data?.aliexpress_ds_order_create_response?.result
            ?.order_list?.number;
        }
      };

      // invoice details to be sent as email
      const invoiceDetails = createInvoiceDetails(aliexpressOrderId);

      localStorage.setItem("invoiceDetails", JSON.stringify(invoiceDetails));

      //create unique transactionId for bulk orders
      const { randomUUID } = new ShortUniqueId({ length: 10 });

      const transactionId = randomUUID();

      localStorage.setItem("transactionId", transactionId);

      const allOrderIds = combineOrderIds(
        totalCartQuantity,
        data,
        aliexpressOrderId
      );

      localStorage.setItem("allOrderIds", JSON.stringify(allOrderIds));

      // handleOrderPaymentUpdate(
      //   paymentTerm,
      //   transactionId,
      //   "NOT PAID",
      //   allOrderIds
      // );

      // check if the payment term matches the specified term
      const isPaymentTerm = (paymentTerm, term) => paymentTerm === term;

      const isPaymentForBothAliexpressAndTofa = (
        data,
        data1,
        paymentTerm,
        term
      ) => isPaymentTerm(paymentTerm, term) && data && data1;

      const isJustAliexpressProduct = (
        totalCartQuantity,
        cart,
        data,
        paymentTerm,
        term
      ) =>
        totalCartQuantity < 1 &&
        cart.length > 0 &&
        isPaymentTerm(paymentTerm, term) &&
        data;

      const isJustTofasProduct = (
        totalCartQuantity,
        cart,
        data,
        paymentTerm,
        term
      ) =>
        totalCartQuantity > 0 &&
        cart.length < 1 &&
        isPaymentTerm(paymentTerm, term) &&
        data;

      const checkPaymentConditions = (
        data,
        data1,
        totalCartQuantity,
        cart,
        paymentTerm,
        term
      ) => {
        return (
          isPaymentForBothAliexpressAndTofa(data, data1, paymentTerm, term) ||
          isJustAliexpressProduct(
            totalCartQuantity,
            cart,
            data,
            paymentTerm,
            term
          ) ||
          isJustTofasProduct(totalCartQuantity, cart, data, paymentTerm, term)
        );
      };

      // Checking if user selected MoMo as payment option
      if (
        checkPaymentConditions(
          data,
          data1,
          totalCartQuantity,
          cart,
          paymentTerm,
          "MoMo"
        )
      ) {
        setOpenMoMoPaymentModal(true);
      } else if (
        checkPaymentConditions(
          data,
          data1,
          totalCartQuantity,
          cart,
          paymentTerm,
          "TransactPay"
        )
      ) {
        InitiateTransactPay(transactionId, invoiceDetails, allOrderIds);
        // pay();
        // makePayment(transactionId);
      } else if (
        checkPaymentConditions(
          data,
          data1,
          totalCartQuantity,
          cart,
          paymentTerm,
          "PAYNOW"
        )
      ) {
        // Checking if user selected Flutterwave (PayNow) as payment option
        handleFlutterPayment({
          callback: (response) => {
            const transactionId = response.tx_ref.toString();
            const totalAmountUSD = grandTotal();
            const userId = user.id;
            const savedOrderIds = localStorage.getItem("orderIds");
            const OrderIds = JSON.parse(savedOrderIds);
            const savedAllOrderIds = localStorage.getItem("allOrderIds");
            const allOrderIds = JSON.parse(savedAllOrderIds);
            const savedTransactionId = localStorage.getItem("transactionId");
            const TransactionId = JSON.parse(savedTransactionId);

            if (allOrderIds && TransactionId) {
              handleOrderPaymentUpdate(
                "PAYNOW",
                transactionId,
                "PAID",
                allOrderIds
              );
            }

            if (OrderIds) {
              updateOrderStatus(OrderIds);
            }
            handleSendInvoice(invoiceDetails);
            handleEventTag("pay_successful", user.id);
            localStorage.removeItem("cart");
            handleDelete(cartItems.cart);
            axiosInstance
              .post("/payment", {
                transactionId,
                userId,
                totalAmountUSD,
              })
              .then((response) => {
                navigate("/order/success-message");
              })
              .catch((error) => {
                console.error(error);
                navigate("/order/success-message");
                toast.error(
                  `${error.response.data.errors[0].message}`,
                  toastOptions
                );
              });
          },
          onClose: () => {
            console.log("Payment canceled or closed.");
          },
        });
      } else {
        toast.success(
          "Your order has been recorded. Kindly make payment to complete order.",
          toastOptions
        );
        setTimeout(() => {
          handleDelete(cartItems.cart);
          navigate(`/order/bank-payment/${cartItems?.cartPrice}`);
        }, 4000);
      }
    } catch (error) {
      console.log("error placing order", error);

      if (error?.aliexpress_ds_order_create_response?.result?.error_msg) {
        toast.error(
          error.aliexpress_ds_order_create_response.result.error_msg,
          toastOptions
        );
        handleEventTag(`e_ali_checkout_${error}`, user.id);
      } else if (error?.response?.data?.errors[0].message) {
        toast.error(error?.response?.data?.errors[0].message, toastOptions);
        handleEventTag(
          `e_checkout_${error.response.data.errors[0].message}`,
          user.id
        );
      } else {
        handleEventTag(`e_checkout`, user.id);
        toast.error(`Network error!`, toastOptions);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
    setSelectedCurrenci,
    paymentTerm,
    setPaymentTerm,
    setUserDetails,
    userDetails,
    currentSelectedCurrency,
    selectedCurrenci,
    totalCartQuantity,
    grandTotal,
    totalCost,
    loadMoMo,
    handleCancelMoMoPaymentModal,
    openMoMoPaymentModal,
    handleSubmitMoMoPayment,
    handleMoMoPaymentDetailsChange,
    momoPaymentDetails,
    formatCountdown,
    handleConfirmMoMoPayment,
    isCodeStep,
    countdown,
    confirmLoadMoMo,
    aliexpressTotalCostafterConvertToSelectedCurrency,
  };
};
